<template>
  <UpmApp :theme="theme" loadingSecondaryColor="tertiary">
    <template #header>
      <Header />
    </template>

    <template #loading-title>
      <i18n-t
        keypath="basket.loading.title"
        tag="span"
        for="basket.loading.exciting"
        class="font-semibold text-primary"
      >
        <mask class="bg-accent leading-relaxed">{{
          t("basket.loading.exciting")
        }}</mask>
      </i18n-t>
    </template>

    <template #footer>
      <Footer />
    </template>
  </UpmApp>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { UpmApp } from "@upmind-automation/client-vue";
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
import theme from "@/assets/theme";

const { t } = useI18n();
</script>
