<template>
  <article>
    <UpmContentSection>
      <UpmBasketLoading
        class="min-h-screen"
        skrim="light"
        :text="t('basket.loading.text')"
        :animated-icon="{
          icon: 'basket',
          delay: 250,
          primaryColor: 'base-foreground',
          secondaryColor: 'tertiary',
          size: '4xl',
        }"
      >
        <template #title>
          <i18n-t
            keypath="basket.loading.title"
            tag="span"
            for="basket.loading.exciting"
            class="font-semibold text-primary"
          >
            <mask class="bg-accent leading-relaxed">{{
              t("basket.loading.exciting")
            }}</mask>
          </i18n-t>
        </template>
      </UpmBasketLoading>
    </UpmContentSection>
  </article>
</template>

<script lang="ts" setup>
// --- external
import { useI18n } from "vue-i18n";
const { t } = useI18n();

// --- internal
import {
  useBasket,
  useBasketCurrency,
  useBasketPromotions,
} from "@upmind-automation/client-vue";

// -- components
import {
  UpmBasketLoading,
  UpmContentSection,
} from "@upmind-automation/client-vue";

// -- utils
import { utils } from "@upmind-automation/client-vue";
const { usePendingBasketItems, useQueryParams } = utils;

// ---types
// -----------------------------------------------------------------------------

const { syncPendingBasketItems, navigateNextBasketItem } =
  usePendingBasketItems();

const { isReady } = useBasket();
const { update: updateCurrency } = useBasketCurrency();
const { add: addPromotion } = useBasketPromotions();

// ---------------------------------------------------

await isReady();

// set the currency if provided
const { currency, coupon } = useQueryParams();
if (currency) updateCurrency({ code: currency });
if (coupon) addPromotion(coupon);

// add our product(s) to the basket, but dont wait for any states to complete
const animation = new Promise(resolve => setTimeout(resolve, 2_000));
await Promise.all(syncPendingBasketItems());
await animation; // ensure we wait for the animation to complete
// finally navigate to the next basket route
navigateNextBasketItem();

// ---------------------------------------------------
</script>
