// --- external
import { spawn } from "xstate";

// --- internal
import itemMachine from "../item.machine";
import { ItemActions as actions } from "./actions";
import services from "./services";

// --- utils
import { get, uniqueId, map } from "lodash-es";

// --- types
import type { ICompany } from "./types";
import type { JsonSchema, UISchemaElement } from "@jsonforms/core";

// --------------------------------------------------------

// TODO: export const useSchema = ({ addresses, emails, phones, baseModel }) => {
export const useSchema = ({ addresses, emails, phones, baseModel }: any) => {
  const choices = {
    addresses: addresses.getItems(),
    emails: emails.getItems(),
    phones: phones.getItems(),
  };

  const schema = {
    type: "object",
    title: "Address Fields",
    required: ["name", "addressId", "emailId"],
    properties: {
      id: {
        type: ["string", "null"],
        title: "ID",
        description: "The AutoGenerated ID of this Company.",
        readOnly: true,
      },

      // ---
      name: {
        type: "string",
        title: "Name",
      },

      addressId: {
        type: "string",
        title: "Address",
        default: baseModel.addressId,
        oneOf: !choices?.addresses?.length
          ? undefined
          : map(choices.addresses, item => {
              return {
                const: item.id,
                title: item.name,
              };
            }),
        lookup: addresses.search,
      },

      emailId: {
        type: "string",
        title: "Email",
        default: baseModel.emailId,
        oneOf: !choices?.emails?.length
          ? undefined
          : map(choices.emails, item => {
              return {
                const: item.id,
                title: item.email,
              };
            }),
        lookup: emails.search,
      },

      phoneId: {
        type: "string",
        title: "Phone",
        default: baseModel.phoneId,
        oneOf: !choices?.phones?.length
          ? undefined
          : map(choices.phones, item => {
              return {
                const: item.id,
                title: item.full_phone,
              };
            }),
        lookup: phones.search,
      },

      regNumber: {
        type: ["string", "null"],
        title: "Registrration number",
      },
      vatNumber: {
        type: ["string", "null"],
        title: "Registered tax/VAT id",
      },
      // vatPercent: {
      //   type: ["string", "null"],
      //   title: "VAT percent",
      // },

      // ---

      default: {
        type: ["boolean", "null"],
        title: "Make this the default company?",
      },
    },
  };

  // if (id) {
  //   schema.required.push("name");
  //   schema.required.push("type");
  // }

  return schema as JsonSchema;
};

export const useUischema = () => {
  const schema = {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/name",
        options: {
          autoFocus: true,
          autocomplete: "off",
          placeholder: "My company name",
        },
      },
      {
        type: "Control",
        scope: "#/properties/vatNumber",
        options: {
          autocomplete: "off",
          placeholder: "Registered tax/VAT id",
        },
      },
      {
        type: "Control",
        scope: "#/properties/regNumber",
        options: {
          autocomplete: "off",
          placeholder: "Registration number",
        },
      },

      // ---
      {
        type: "Control",
        scope: "#/properties/addressId",
        options: {
          autocomplete: "off",
          placeholder: "Select an Address...",
          itemLabel: "name",
          itemValue: "id",
          align: "start",
          side: "bottom",
        },
      },
      {
        type: "Control",
        scope: "#/properties/emailId",
        options: {
          autocomplete: "off",
          placeholder: "Select an email...",
          itemLabel: "email",
          itemValue: "id",
          align: "start",
          side: "bottom",
        },
      },
      {
        type: "Control",
        scope: "#/properties/phoneId",
        options: {
          autocomplete: "country",
          placeholder: "Please select a Phone Number  ...",
          itemLabel: "full_phone",
          itemValue: "id",
          align: "start",
          side: "bottom",
        },
      },
      // ---
      // We dont ever show this field as it is set by an action
      // {
      //   type: "Control",
      //   scope: "#/properties/default",
      //   options: {
      //     // toggle: true
      //   }
      // }
    ],
  };

  return schema as UISchemaElement;
};

// --------------------------------------------------------

export const spawnItem = (model?: ICompany) => {
  try {
    const name = get(model, "id", uniqueId("item_"));
    return spawn(
      itemMachine
        .withConfig({
          // @ts-ignore
          actions,
          // @ts-ignore
          services,
        })
        .withContext({ model }),
      {
        name,
        sync: true,
      }
    );
  } catch (err) {
    console.error("CompanyListings", "spawnItem", { model });
  }
};
