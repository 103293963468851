// --- external

// --- internal
import { useApi } from "../api";
import { useBrand, BrandConfigKeys } from "../brand";

// --- utils
import { useTime } from "../../utils";
import { set } from "lodash-es";

// --- types

// --------------------------------------------------------
// ENUMS

// --------------------------------------------------------
// SERVICE METHODS
// Invoked by machines, providing context and event data
// this will process the request and return a promise
// ---

// --------------------------------------------------------
// EXPORTS

export default {
  fetch,
} as any;
