// --- external
import { createMachine, assign, sendParent, actions } from "xstate";
const { escalate } = actions;

// --- internal
import services from "./services";
import { useFeedback } from "../feedback";
const { addError, addSuccess } = useFeedback();

// --- utils
import { useApprovalParser } from "./utils";
import { useTime, useValidationParser } from "../../utils";
import { isEmpty } from "lodash-es";

// --- types
import type { PaymentContext, PaymentEvent } from "./types";
import { responseCodes } from "../api";

// --------------------------------------------------------

export default createMachine(
  {
    // tsTypes: {} as import("./payment.machine.typegen").Typegen0,
    id: "paymentManager",
    predictableActionArguments: true,
    initial: "loading",
    context: {
      error: null,
    } as PaymentContext,
    states: {
      loading: {
        invoke: {
          src: "load",
          onDone: {
            target: "checking",
            actions: ["setContext"],
          },
          onError: {
            target: "#error",
            actions: ["setError", "setFeedbackError"],
          },
        },
      },

      // ---
      checking: {
        entry: ["clearError"],
        invoke: {
          src: "validate",
          onDone: { target: "#valid" },
          onError: {
            target: "#invalid",
            actions: ["setError"],
          },
        },
      },

      invalid: {
        id: "invalid",
        on: {
          "xstate.update": {
            target: "checking",
          },
        },
      },

      valid: {
        id: "valid",
        always: [
          {
            target: "processing",
            cond: "hasPaymentDetails",
          },
        ],
        on: {
          PAY: { target: "processing" },
          "xstate.update": {
            target: "checking",
          },
        },
      },

      processing: {
        invoke: {
          src: "update",
          onDone: {
            target: "processed",
            actions: ["setPayment", "providePayment"],
          },
          onError: {
            target: "error",
            actions: ["setError", "setFeedbackError"],
          },
        },
      },

      processed: {
        id: "processed",
        after: {
          wait: [
            {
              target: "approving",
              cond: "needsApproval",
              actions: ["setApproval"],
            },
            {
              target: "complete",
              actions: "setFeedbackSuccess",
            },
          ],
        },
      },

      approving: {
        initial: "redirecting",
        states: {
          redirecting: {
            invoke: {
              src: "redirect",
              onDone: {
                target: "offsite",
              },
              onError: {
                target: "#error",
                actions: ["setError", "setFeedbackError"],
              },
            },
          },
          offsite: {
            on: {
              APPROVED: {
                target: "#complete",
              },
            },
          },
        },
      },

      complete: {
        id: "complete",
        type: "final",
        data: ({ payment }: PaymentContext, _event: PaymentEvent) => payment,
      },

      error: {
        entry: escalate(({ error }, _event) => ({
          data: error,
        })),
        id: "error",
        type: "final",
      },
    },
  },
  {
    actions: {
      setContext: assign(
        // (_context: PaymentDetailsContext, { data }: PaymentDetailsEvent) => data
        (_context: any, { data }: any) => data
      ),

      setPayment: assign({
        payment: (_context, { data }) => data,
      }),

      setApproval: assign({
        payment: context => useApprovalParser(context),
      }),

      providePayment: sendParent(({ payment }) => ({
        type: "PAYMENT",
        data: payment,
      })),

      // ---
      // @ts-ignore
      setFeedbackSuccess: (_context: PaymentContext, _event: PaymentEvent) => {
        addSuccess("Successfully made payment");
      },

      // @ts-ignore
      setFeedbackError: ({ error }: PaymentContext, _event: PaymentEvent) => {
        if (!error || error?.code == responseCodes.Unprocessable_Entity) return;
        addError({
          title:
            error?.title || "We experienced an error processing your payment",
          copy: error?.message,
          data: error?.data,
        });
      },

      setError: assign({
        error: (_context, { data }: any) => {
          let error = data?.error;
          if (error?.code == responseCodes.Unprocessable_Entity) {
            // lets parse/override our error message and data
            // this is to generate valid json schema validation errors
            error = useValidationParser(error);
          }

          return error || data;
        },
      }),

      clearError: assign({ error: null }),
    },

    guards: {
      // @ts-ignore
      hasPaymentDetails: (
        { paymentDetails }: PaymentContext,
        _event: PaymentEvent
      ) => !isEmpty(paymentDetails),

      // @ts-ignore
      needsApproval: ({ payment }: PaymentContext, _event: PaymentEvent) =>
        !!payment.approval_url,

      // @ts-ignore
      hasNoOutstandingBalance: (
        _context: PaymentContext,
        _event: PaymentEvent
      ) => {
        // TODO: check if there is an outstanding balance
        return true;
      },
    },

    delays: {
      // @ts-ignore
      error: () => useTime().ERROR,
      wait: () => useTime().WAIT,
    },

    // @ts-ignore
    services,
  }
);
